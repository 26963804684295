// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$frontend-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $frontend-primary,
      accent: $frontend-accent,
      warn: $frontend-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply text-gray-800;
}

button,
a:not(.input) {
  @apply transition-all;
  @apply ease-in-out;
  @apply duration-150;
}
button:not(.input) {
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply ring-monza-400;
}
button:not(.input).mat-calendar-body-cell {
  @apply ring-green-400;
}
button.input {
  @apply focus:ring-monza-400;
  @apply focus:border-monza-500;
  @apply focus:ring-1;
  @apply focus:ring-offset-0;
  @apply outline-none;
}

// helper
.bg-graph-paper {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.cc-bottom {
  left: 4px !important;
}

// table
table.mat-table.ass-table {
  @apply table-auto;
  @apply min-w-full;
  // header
  .mat-header-row {
    @apply h-12;
    @apply bg-gray-50;
  }
  .mat-header-cell {
    @apply text-sm;
    @apply text-left;
    @apply font-medium;
    @apply leading-[1.875rem];
    @apply px-4;
  }
  .mat-header-cell:first-of-type,
  .mat-cell:first-of-type {
    @apply pl-8;
  }
  .mat-header-cell:last-of-type {
    @apply pr-8;
  }
  // rows
  .mat-row {
    @apply h-auto;
  }

  .mat-cell {
    @apply p-4;
    @apply bg-white;
  }
  .mat-cell:first-of-type {
    @apply pl-8;
  }
  .mat-cell:last-of-type {
    @apply pr-8;
  }
}

.cdk-overlay-container {
  .mat-tooltip {
    @apply text-center;
    @apply z-50;
    @apply py-2;
    @apply px-3;
    @apply text-sm;
    @apply font-medium;
    @apply text-white;
    @apply w-auto;
    @apply bg-monza-500;
    @apply opacity-0;
    @apply pointer-events-none;
    @apply overflow-visible;
    @apply rounded-none;
  }

  .mat-tooltip::before {
    content: '';
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    bottom: -4px;
    top: auto;
    @apply bg-monza-500;
  }

  .mat-tooltip-panel-below {
    .mat-tooltip::before {
      top: -4px;
      bottom: auto;
    }
  }

  .mat-tooltip-panel-left {
    .mat-tooltip::before {
      right: -4px;
      top: 12px;
      left: auto;
      bottom: auto;
    }
  }
}

.scrollbar::-webkit-scrollbar {
  @apply w-1;
  @apply h-9;
}

.scrollbar::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-monza-500;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-monza-300;
}

.scrollbar {
  scrollbar-width: thin;
}

.mtx-mdc-tooltip-surface {
  max-width: none !important;
  max-height: none !important;
  background-color: white !important;
  color: #555 !important;
  border: 1px solid #e0e0e0 !important;
}
